@tailwind base;
@tailwind components;
@tailwind utilities;

.rs-table-header-row-wrapper {
  position: fixed !important;
}

.rs-table-row-expanded {
  background: lightslategray !important;
  border-top: 0 !important;
  opacity: 20%;
  bottom: 0px !important;
  height: 60px !important;
}

.rs-table-row-header .rs-table-cell {
  background: lightslategray !important;
}
